import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { PageText, PageH1 } from "./../styledcomponents/layoutstyles";
import styled from "styled-components";
// import Config from "./../../config";

const RequestFormDiv = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  & p {
    margin: 0;
    text-indent: 0;
    & + p {
      margin: 16px 0 0 0;
      text-indent: 0;
    }
  }
  & h2 {
    color: var(--red);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
  }
  & input[type="text"],
  & input[type="email"] {
    width: 100%;
    box-sizing: border-box;
  }
  & input[type="submit"] {
    background-color: var(--red);
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    margin: 16px 0 0 auto;
    transition: 0.5s;
    &:hover {
      background-color: vaR(--blue);
    }
  }
`;

const RequestReviewCopy = () => {
  return (
    <Layout title={`Ring On Deli: Request Form`}>
      <PageText>
        <PageH1>
          About The Official
          <br />
          <em>Ring On Deli</em> Request Form
        </PageH1>
        <p>Hello, Booksellers and Others!</p>
        <div>
          <p>&nbsp;</p>
        </div>
        <p>
          Please fill out this form to request a sample paperback copy of RING
          ON DELI (New Salem Books). Bookstores can order RING ON DELI from
          Ingram (55% retailer discount, fully returnable) or directly from New
          Salem Books (same terms).
        </p>
        <p>
          To learn more about the book—reviews! blurbs! media coverage! “free
          samples”!—visit <Link to="/">www.ringondeli.com</Link>. To invite Eric
          to visit or do a reading at your store, either remotely or in-person,
          or to contact New Salem Books for any other reason, just e-mail{" "}
          <a href="mailto:newsalembooks@gmail.com">newsalembooks@gmail.com</a>.
        </p>
        <p style={{ margin: "16px 0 32px 50%", textIndent: "0" }}>
          Ring on!
          <br />
          New Salem Books
        </p>
        <RequestFormDiv>
          <PageH1>The Request Form</PageH1>{" "}
          <form
            action="https://formcarry.com/s/vOGPUCVIrCJE"
            method="POST"
            accept-charset="UTF-8"
          >
            <p>
              <label>
                <span>Your name:</span>
                <input type="text" name="name" />
              </label>
            </p>
            <p>
              <label>
                <span>Position or Affiliation:</span>
                <input
                  type="text"
                  name="position"
                  placeholder="e.g., “Book Buyer, Mainely Books”"
                />
              </label>
            </p>
            <p>
              <label>
                <span>Mailing Address (for your book!):</span>
                <input type="text" name="address" />
              </label>
            </p>
            <p>
              <label>
                <span>Your email address:</span>
                <input type="email" name="email" />
              </label>
            </p>{" "}
            <p>
              <label>
                <span>Your phone number:</span>
                <input type="text" name="subject" />
              </label>
            </p>
            <input type="hidden" name="_gotcha" />
            <p className="checkbox">
              <label>
                <input type="checkbox" name="sendupdates" />
                <span>
                  Yes! Send me very occasional updates on <em> Ring On Deli</em>{" "}
                  and Eric’s writing.
                </span>
              </label>
            </p>
            <input type="submit" value="Send" />
          </form>
        </RequestFormDiv>
      </PageText>
    </Layout>
  );
};

export default RequestReviewCopy;
